var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"PPAxSxM1-PsJoCUHhWWDL"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { environment as env } from '@doltech/core/lib/environment/environment';
import { dolSentrySharedConfig } from "@doltech/core/lib/sentry/sentry-shared-config";

const isProdBuild = () => {
  return (
    // env.NEXT_PUBLIC_SENTRY_ENV === 'INTEGRATION' ||
    env.NEXT_PUBLIC_SENTRY_ENV === 'PRODUCTION'
  );
};

if (isProdBuild()) {
  const SENTRY_DSN = process.env.SENTRY_DSN || env.NEXT_PUBLIC_SENTRY_DSN;
  setTimeout(() => {
    import('@sentry/nextjs').then((Sentry) =>
      Sentry.init({
        dsn:
          SENTRY_DSN || 'https://8b5107f4649e4190a3fe21bbabb3defb@o716772.ingest.sentry.io/5779736',
        environment: env.NEXT_PUBLIC_SENTRY_ENV,
        ...dolSentrySharedConfig
      })
    );
  }, 5000);
}
